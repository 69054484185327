import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
function convertEpochToString(epoch,format){
    const date = new Date(epoch*1000);
    switch(format)
    {
        case "day mon time":
            return `${date.toDateString().replace(/([\s]....$)/,"")} ${date.toLocaleTimeString()}`;
        case "time":
            return `${date.toLocaleTimeString()}`;
        case "mm/dd/yy time":
            return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
        default:
            return date.toDateString();
    }
}
class Overview extends Component {
    getTimeUntilSunset = (unixTime)=>{
        const sunset = new Date(unixTime*1000);
        const currentTime = new Date();
        //console.log(`${sunset} - ${currentTime}`)
        const diffTimeMS = (Math.abs(sunset.getTime() - currentTime.getTime()))/1000;
        const diffTimeMins = Math.floor(diffTimeMS/60);
        const diffTimeHrs = Math.floor(diffTimeMins/60);
        const diffTimeRdr = diffTimeMins-diffTimeHrs*60;
        
        return {hours:diffTimeHrs.toString().padStart(2,"0"),mins:diffTimeRdr.toString().padStart(2,"0")};
    }
    state ={
        timeUntilSunset: {},
        overview:{}
    }
    updateTimeUntilSunset = (time)=>{
        
        this.setState(state=>({
            ...state, timeUntilSunset: this.getTimeUntilSunset(time)
       }))
       this.timeoutId = setTimeout(this.updateTimeUntilSunset.bind(this),1000,time);
    }
   componentWillReceiveProps(nextProps){
        // if(nextProps.overview !=null){
        //     this.updateTimeUntilSunset(nextProps.overview.sunset);

        // }
   }
   componentWillUnmount(){
        clearTimeout(this.timeoutId);
   }
    
  render() {
    if(this.props.overview ==null){
        return (
          <section>
            <p className="text-center">Current weather data was not found</p>
            
          </section>
        )
    }
    return (
      <div>
          <Row>
            <Col className="text-center">
                <h5>{this.props.overview.location}</h5>
            </Col>
          </Row>
          {/* <Row>
            <Col className='text-right'>
                {this.state.timeUntilSunset.hours}:{this.state.timeUntilSunset.mins} until Sunset ({(new Date(this.props.overview.sunset*1000).toLocaleTimeString().replace(/:\d+ /, ' '))})
                
            </Col>
          </Row> */}
          <Row className="align-items-center">
            <Col className="text-right col-6 col-sm-6">
                <img src={this.props.overview.weather.icon} style={{height:"100%",width:"100%",minWidth:"65px",maxWidth:"85px"}}></img>
            </Col>
            <Col className="text-left col-6 col-sm-6">
               <span style={{fontSize:"50px"}}>
                {this.props.overview.temperature}<span>&#176;</span>F
               </span>
               
               
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className='text-center col-7'>
            <Button variant="outline-info" block onClick={this.props.unsetZip}>Change Zip</Button>
            </Col>
            <Col className="text-right">
            <span className="text-info">Last Updated:</span>
            <br/>
            <span className="text-success">{convertEpochToString(this.props.overview.time,"mm/dd/yy time")}</span>
            </Col>
          </Row>
          
      </div>
    )
  }
}

export default Overview
