import React, { Component } from "react";
import Header from "./components/Header";
import WeatherSummary from "./components/WeatherSummary";
//import Temperature from './components/Temperature';
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Analyzer from "./components/Analyzer";
import Container from "react-bootstrap/Container";
import ZipInput from "./components/ZipInput";
import Axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
function setCookie(cname, cvalue) {
  document.cookie = cname + "=" + cvalue + ";path=/;domain=nubgrammer.com";
}

function getCookie(cname) {
  const regex = new RegExp(`(?:(?:^|.*;\s*)${cname}\s*\=\s*([^;]*).*$)|^.*$`);
  const cookieValue = document.cookie.replace(regex, "$1");
  return cookieValue;
}

function getZip() {
  const zip = getCookie("zip");
  return zip;
}
class App extends Component {
  state = {
    zip: "",
    zipIsEntered: false,
    forecast: null,
    overview: null
  };
  setZip = zip => {
    this.setState(state => ({
      ...state,
      zip: zip,
      zipIsEntered: true
    }));
    setCookie("zip", zip);
  };
  unsetZip = () => {
    this.setState(state => ({
      ...state,
      zipIsEntered: false
    }));
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.zip !== prevState.zip) {
      console.log(this.state.zip);
      Axios.get(
        // `http://api.apixu.com/v1/forecast.json?key=d950f88f738641859e044552192702&days=7&q=${
        //   this.state.zip
        // }`
        `https://us-central1-weather-app-1552191617462.cloudfunctions.net/getWeather?zip=${this.state.zip}`
      ).then(res => {
        this.setState(state => ({
          ...state,
          overview: {
            time: res.data.location.localtime_epoch,
            temperature: res.data.current.temp_f,
            location: `${res.data.location.name}, ${
              res.data.location.region
            }, ${res.data.location.country}`,
            weather: {
              main: res.data.current.condition.text,
              icon: res.data.current.condition.icon
            }
          },
          forecast:res.data.forecast.forecastday.slice(-6).map(day=>{
              const mappedOb = {}
              mappedOb.weather={main:day.day.condition.text,
                                description:day.day.condition.text,
                              icon:day.day.condition.icon};
              mappedOb.date=day.date_epoch;
              mappedOb.temp={high:day.day.maxtemp_f,low:day.day.mintemp_f}
              return mappedOb;
          })
        }));
        Axios.get(``)
      });
    }
  }
  // weather: {
  //   main:"Clouds",
  //   description:"broken clouds",
  //   icon:"01d.png"
  // },
  // date:1485794875,
  // temp:{high:60, low:40}
  componentDidMount() {
    if (getZip().length > 0) this.setZip(getZip());
  }
  render() {
    return (
      <div className="App">
        <ZipInput zipIsEntered={this.state.zipIsEntered} setZip={this.setZip} />
        <Container
          style={{
            maxWidth: "500px",
            position: "absolute",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)"
          }}
        >
          <Header />
          <hr />
          <WeatherSummary
            unsetZip={this.unsetZip}
            zipIsEntered={this.state.zipIsEntered}
            zip={this.state.zip}
            overview={this.state.overview}
            forecast={this.state.forecast}
          />
          <hr/>
          {/* <Analyzer summary={this.state.summary}/> */}
         
          <Row>
            <Col className="text-right">
              Powered by <a href="https://www.apixu.com/" title="Weather API">Apixu.com</a>
            </Col>
          </Row>
          <hr/>
        </Container>
      </div>
    );
  }
}

export default App;
