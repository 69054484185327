import React, { Component } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
const popover = (description)=>(
    <Tooltip>
      {description}
    </Tooltip>
  );
const getDay = epoch=>{
  const date = new Date(epoch*1000);
  const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  console.log(date.getDay())
  return days[date.getDay()];
}
class Forecast extends Component {
   link = (weather)=>{
    return `http://openweathermap.org/img/w/${weather.icon}`
   }

  render() {
    
    if(!this.props.forecast){
      return (
        <section>
          <p className="text-center">Current forecast data was not found</p>
        </section>
      )
    }
    return (
     
      <Row className="forecast">
      
        {this.props.forecast.map((forecast)=>(
            <Col className="text-center">
            <h6>{(new Date(forecast.date*1000)).toDateString().replace(/([\s]...[\s]..[\s]....$)/,"")}</h6>
            
            <OverlayTrigger key="top" placement="top" overlay={popover(forecast.weather.description)}>
            <img src={forecast.weather.icon} style={{height:"45px",width:"45px"}}></img>
           
            </OverlayTrigger>
            <br/>
          
            {/* <span>{forecast.weather.description}</span><br/> */}
            <span className="text-success">{forecast.temp.high}&#176;F </span><br/>
            <span className="text-info">{forecast.temp.low}&#176;F </span>
            
            </Col>
        ))}
        
      </Row>
   
    )
  }
}

export default Forecast
