import React, { Component } from 'react'
import Overview from './Overview';
import Forecast from './Forecast';
import Button from 'react-bootstrap/Button'
class WeatherSummary extends Component {
  render() {
    if(!this.props.zipIsEntered){
      return (
        <section>
          <p className="text-center">A zip code has not been entered</p>
          <Button variant="outline-info" block onClick={this.props.unsetZip}>Change Zip</Button>
        </section>
      )
    }
    return (
      <section>
        <Overview unsetZip={this.props.unsetZip} zip={this.props.zip} overview={this.props.overview}/>
        <hr/>
        <Forecast forecast={this.props.forecast}/>
      </section>
    )
  }
}

export default WeatherSummary
