import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

class ZipInput extends Component {
  state = {
    show: null,
    zip: "",
    error:""
  };
  handleClose = () => {
    this.setState(state => ({ ...state, show: false,error:"" }));
  };
  handleShow = () => {
    this.setState(state => ({ ...state, show: true,zip:"" }));
  };
  handleChange = event => {
    event.persist();
    this.setState(state => ({ ...state, zip: event.target.value }));
  };
  handleSubmit = (event)=>{
    event.preventDefault();
    if(this.state.zip.length==5){
      this.handleClose();
      this.props.setZip(this.state.zip);
    }else{
      this.setState(state=>({...state,error:"Please enter a valid, 5-digit zip code"}))
    }

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.zipIsEntered) {
      this.handleClose();
    } else {
      this.handleShow();
    }
  }
  componentDidMount(){
    if (this.props.zipIsEntered) {
      this.handleClose();
    } else {
      this.handleShow();
    }
  }
  render() {
    // if (this.props.zipIsEntered) {
    //   return null;
    // }
    return (
      <Modal className="dark" centered show={this.state.show} onHide={this.handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Where Should I look?</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>
            <Form.Label>Choose a location by zip code:</Form.Label>
            <Form.Control
              placeholder="Enter your Zip"
              onChange={this.handleChange.bind(this)}
            />
            <span className="text-danger">{this.state.error}</span>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button> */}
            <Button
              variant="outline-info"
              type="submit"
            >
              Go!
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ZipInput;
