import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
class Analyzer extends Component {
  render() {
    return (
      <section>
        <Button variant="outline-info" size="sm" block>
            Analyzer
        </Button>
      </section>
    )
  }
}

export default Analyzer
